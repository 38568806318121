import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClientes: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Tus clientes
            </h2>
            <p>
                El proceso de pre-llenado de tus clientes suele suceder a medida que solicitan facturas sin necesidad de tu intervención.<br />
                Cuando un cliente registrado en Shopify solicite una factura, este cliente se asociará a un Receptor.
            </p>
            <ImageFooted explanation={`Es posible pre-llenar los datos de tus clientes o modificar el receptor si lo necesitas.`}>
                <IMG_Shopify file={'clientes'} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/shopify/claves-pedimentos`} next={`/ecommerce/shopify/cancelar-facturas`}/>
        </div>
    </Layout>
)

export default ShopifyClientes;